import { Component, OnInit } from '@angular/core';
import { ApiService } from '../../services/api.service';
import * as moment from 'moment';
import * as _ from 'lodash';
import { MatDialog } from '@angular/material/dialog';
import { ImportLogsComponent } from '../import-logs/import-logs.component';
import { DialogImportLogComponent } from '../dialog-payment-import-log/dialog-payment-import-log.component';
import { SnotifyService } from 'ng-snotify';
import { MatSnackBar } from '@angular/material/snack-bar';
import { NewUpdateInsuranceInformationComponent } from '../modal/new-update-insurance-information/new-update-insurance-information.component';
import { DialogLoadingComponent } from '../dialog-loading/dialog-loading.component';
import { UpdateAmdCodeInsuranceModalComponent } from '../modal/update-amd-code-insurance-modal/update-amd-code-insurance-modal.component';
import { ActivatedRoute } from '@angular/router';
import { UpdateVisitProcCodeModalComponent } from '../modal/update-visit-proc-code-modal/update-visit-proc-code-modal.component';
import { zip } from 'rxjs';
import { UpdateVisitChartDocModalComponent } from '../modal/update-visit-chart-doc-modal/update-visit-chart-doc-modal.component';
import { UpdateDiagCodeModalComponent } from '../modal/update-diag-code-modal/update-diag-code-modal.component';
import { UpdateModifierCodeModalComponent } from '../modal/update-modifier-code-modal/update-modifier-code-modal.component';
import { ResetVisitModalComponent } from '../modal/reset-visit-modal/reset-visit-modal.component';
import { ViewDocModalComponent } from '../modal/view-doc-modal/view-doc-modal.component';
import { DomSanitizer } from '@angular/platform-browser';
import { EditVisitModalComponent } from '../modal/edit-visit-modal/edit-visit-modal.component';
import * as deepEqual from 'deep-equal';
import * as deepDiff from 'deep-diff';
import { ViewVisitPvUpdateModalComponent } from './view-visit-pvUpdate-modal/view-visit-pvUpdate-modal.component';
import { ConfirmModalComponent } from '../modal/confirm-modal/confirm-modal.component';
import { UpdateGuarantorInformationModalComponent } from '../modal/update-guarantor-information-modal/update-guarantor-information-modal.component';

@Component({
    selector: 'app-import-record',
    templateUrl: './import-record.component.html',
    styleUrls: ['./import-record.component.scss']

})
export class ImportRecordComponent implements OnInit {
    flaggedRetryCount = 0;
    queueCount = '';
    errorCount = '';

    returnCount = '';   
    returnUpdateXmlCount = null;

    returnedVisits = [];
    page: any = { CurrentPage: 0 };
    rows = new Array<any>();
    rowsQueueOrDone = new Array<any>();
    rowsBatchJobs = new Array<any>();
    rowsPaymentImports = new Array<any>();
    loading: boolean = false;
    filter = {
        visitStartDate: moment().subtract(1, 'months').toDate(),
        visitEndDate: new Date(),
        clinicIds: [],
        finClassIds: [],
        patSearch: ''
    };
    clinics = [];
    finClasses = [];
    generatingAndDownLoadCSV = false;
    unqueuing = false;
    reruning = false;
    currentTabIndex = 1;
   
    importErrors = [];
    importErrorsCount;

    importQueued = [];
    payloadPaymentImport: any = {
        isAsc: false,
        sortValue: "",
        filterStartDate: moment().subtract(1, 'months').toDate(),
        filterEndDate: new Date()
    };
    isLoadingError: boolean = false
    cardInfos = []
    visitIds = []
    dialogLoading: any;
    highlightVisitId = '';

    isLoadingVisitImportHistory = true;
    visitSourceImportChanges = [];
    pageVisitChangeEvent = {
        PageSize: 5,
        PageNumber: 0,
        RowCount: 0,
        SearchText :""
    }

    isLoadingChartChange = true;
    visitImportChartChanges = [];
    pageChartChangeEvent = {
        PageSize: 10,
        PageNumber: 0,
        RowCount: 0,
        SearchText: "",
        startDate: moment().subtract(1, 'months').toDate(),
        endDate: new Date()
    }
    assignedOfficeKeys = [] as any[];

    visitImportStautsDones =[]
    pageVisitDoneEvent = {
        pageSize: 20,
        pageNumber: 0,
        rowCount: null,
        isLoading: true
    }

    downloadText = "Download Summary"
    companyEMR: any;
    constructor(private apiService: ApiService, private matDialog: MatDialog,
        private snotifyService: SnotifyService, private _snackBar: MatSnackBar,
        private activatedRoute: ActivatedRoute, public sanitizer: DomSanitizer) {
        this.apiService.getClinics().subscribe(r => {
            this.clinics = r;
        });
        this.apiService.getFinClasses().subscribe(r => {
            this.finClasses = r;
        });       
    }

    ngOnInit(): void {
        this.getUserCompanyEMR();
        this.getQueueImportLog();
        this.getErrorImports();
        this.getReturnedVisits();
        this.getOfficeKeysByUserAssigned();
        this.getVisitReturnDonePagination();
    }


    getUserCompanyEMR() {
        this.apiService.getUserCompanyEMR().subscribe(result => {
            this.companyEMR = result;
        })
    }

    getOfficeKeysByUserAssigned() {
        this.apiService.getOfficeKeysByUserAssigned().subscribe(r => {
            this.assignedOfficeKeys = r;
        })
    }
    getReturnedVisits() {
        this.returnCount = 'loading..'
        const lastVisitReturns: { [visitId: number]: any } = {};

        this.apiService.getReturnedVisits().subscribe(r => {

            _.each(r, item => {
                const visitId = item.visitReturn.visitId;
                if (!lastVisitReturns[visitId] || item.visitReturn.id > lastVisitReturns[visitId].visitReturn.id) {
                    lastVisitReturns[visitId] = item;
                }
            });

            this.returnedVisits = Object.values(lastVisitReturns);
            this.returnCount = _.filter(this.returnedVisits, (visit) => visit.visitReturn.returnStatus == 1).length.toString();

            console.log(this.returnedVisits)
        })
    }



    changePageVisitDoneEvent(event) {
        this.pageVisitDoneEvent.pageNumber = event.offset;
        this.getVisitReturnDonePagination();
    }

    getVisitReturnDonePagination() {
        this.pageVisitDoneEvent.isLoading = true;
        if (!this.pageVisitDoneEvent.rowCount) {
            this.pageVisitDoneEvent.rowCount = 'loading...'
        }
        this.apiService.getDoneReturnedVisits(this.pageVisitDoneEvent).subscribe(data => {
            this.pageVisitDoneEvent.rowCount = data.total;
            this.visitImportStautsDones = data.results;
            this.pageVisitDoneEvent.isLoading = false;
        }, err => {
            this.pageVisitDoneEvent.isLoading = false;
            this.pageVisitDoneEvent.rowCount = null;
        })
    }

    getProcessedIn(pageNum) {
        this.loading = true;

        this.apiService.getProcessedIn(pageNum, this.filter).subscribe(r => {
            console.log(r)
            this.page = r;
            this.rows = r.Results;
            this.page.CurrentPage -= 1
            this.loading = false
        })
    }

    getBatchJob(pageNum) {
        this.loading = true;
        this.apiService.getBatchJob(pageNum).subscribe(r => {
            this.rowsBatchJobs = r.batchJobs
            this.page.currentPage -= 1
            this.page.RowCount = r.total
            this.loading = false
        })
    }

    getQueueImportLog() {
        this.queueCount ='loading..'
        this.apiService.getQueueImportLog().subscribe(r => {
            this.importQueued = r;
            this.queueCount = r.length.toString();
        })
    }

    getErrorImports() {
        this.errorCount = 'loading..';
        this.isLoadingError = true      
        this.apiService.getErrorsImportLog(this.filter).subscribe(r => {
            console.log(r)
            this.importErrors = r.errors;
            console.log(this.importErrors)

            this.importErrorsCount=  this.importErrors.length;
            this.errorCount = r.totalCount.toString();

            let importRetrys = _.filter(this.importErrors, (e) => {return e.importRetryFlagged})
            this.visitIds = _.map(importRetrys, 'visitId')
            _.each(this.importErrors, (e) => {
               if(e.importStatus_Visit){
                    console.log("visit err "+ e.visitId, e.importStatus_Visit)
               }
         
                e.isShowRound = e.importStatus_Pat?.hasError ? false : true
                let findError = "Can not add Insurance because the Insruance record can not be located"
                //e.isShowUpdateQuick = e.importStatus_Payer != null && (e.importStatus_Payer.message.includes(findError) || e.importStatus_Payer.message.includes("no carrier with")) 
                e.isShowUpdateQuick = (e.importStatus_Payer != null && e.importStatus_Payer.hasError);
                if (e.importStatus_Payer != null && e.importStatus_Payer.hasError) {
                    e.importStatus_Payer.payerErrorType = this.checkShowUpdateQuick(e.importStatus_Payer.message)
                }
                e.isShowUpdateProcCode = e.importStatus_Charges != null && e.importStatus_Charges.message.includes('ProcCode') 
                e.isShowUpdateDiagCode = e.importStatus_Charges != null && e.importStatus_Charges.message.includes('DiagCode') 
                e.isShowUpdateModifierCode = e.importStatus_Charges != null && e.importStatus_Charges.message.includes('Modifier code')
                e.isShowUpdateQuickChart = e.importStatus_ChartDoc != null && e.importStatus_ChartDoc.message.includes('DocumentImage is null') 

                e.isShowUpdateGuarantor = (e.guarantorPayerId && e.importStatus_Pat != null && e.importStatus_Pat.message.includes('FK_pt_PatientInfo_misc_HipaaRelationship'));
                e.mainStatus= e.retryCount>=5?'failed':'error';
                e.isShowUpdateOfficeKey = ( e.importStatus_Visit && e.importStatus_Visit.message.includes('The INSERT statement conflicted with the CHECK constraint "CK_appts_Appointments'));
            })


            this.getFlaggedRetry();

            this.isLoadingError = false
        })
    }

    checkShowUpdateQuick(errorMessage) {
        if (errorMessage.trim() == '') {
            return '';
        }
        const lowercasedMessage = errorMessage.toLowerCase();
        if (lowercasedMessage.includes('resparty')) {
            return 'resparty';
        }
        if (lowercasedMessage.includes('add insurance') || lowercasedMessage.includes('carrier') ||  lowercasedMessage.includes('object reference not set to an instance of an object')) {
            return 'carrier';
        }
        return '';
    }

    getFlaggedRetry() {
        this.apiService.getFlaggedRetry(this.filter).subscribe(visitIds => {
            this.flaggedRetryCount = visitIds.length;
            _.each(this.importErrors, item => {
                if (visitIds.indexOf(item.visitId) > -1) {
                    item.importRetryFlagged = true;
                    console.log(item.visitId)
                }
            })

        });
    }

    clearErrorAndEnqueue(visitId) {
        this.apiService.clearErrorAndEnqueue(visitId).subscribe(r => {
            _.remove(this.importErrors, { visitId: visitId })
            this.errorCount = this.importErrors.length.toString();
            this.getQueueImportLog()
        })
    }

    getPaymentImports(pageNum, payload) {
        this.loading = true;
        console.log('payload', payload)
        this.apiService.getPaymentsImportLog(pageNum, payload).subscribe(r => {
            this.rowsPaymentImports = r.batchJobs
            this.page.currentPage -= 1
            this.page.RowCount = r.total
            this.loading = false
        })
    }

    getVisitsByStatus(pageNum, status) {
        this.loading = true;

        this.apiService.getVisitsByStatus(pageNum, status, this.filter).subscribe(r => {
            this.page = r;
            this.rowsQueueOrDone = r.Results;
            this.page.CurrentPage -= 1
            this.loading = false

        })
    }



    setPage(pageInfo) {
        switch (this.currentTabIndex) {
            case 0:
                this.getProcessedIn(pageInfo.offset + 1);
                break;
            case 1:
                this.getVisitsByStatus(pageInfo.offset + 1, "QUEUED")
                break;
            case 3:
                this.getVisitsByStatus(pageInfo.offset + 1, "DONE")
                break;
            case 4:
                this.getBatchJob(pageInfo.offset + 1)
                break;
            case 5:
                this.getPaymentImports(pageInfo.offset, this.payloadPaymentImport)
                break;
        }
    }

    tabChanged(e) {
        this.currentTabIndex = e.index

        console.log("currentTabIndex", this.currentTabIndex)
        this.page = { CurrentPage: 0 };
        this.rows = [];
        this.rowsQueueOrDone = [];
        console.log("currentTabIndex", this.currentTabIndex)
        switch (this.currentTabIndex) {
            case 0:
                this.getProcessedIn(1);
                break;
            case 1:

                this.getVisitsByStatus(1, "QUEUED")
                break;
            case 2:
                 //this.getErrorImports()
                break;
            case 3:
                this.getPaginationImportChanges();
                this.getVisitsByStatus(1, "DONE")
                break;
            case 4:
                this.getPaginationChartChanges();
                this.getBatchJob(1)
                break;
            case 5:
                this.getPaymentImports(0, this.payloadPaymentImport);
                break;

        }
    }

    downloadCSV(index) {
        this.generatingAndDownLoadCSV = true;

        switch (index) {
            case 0:
                this.apiService.getProcessedIn4Report(this.filter).subscribe(r => {
                    this.downloadFile(r, 'ProcessedIn');
                    this.generatingAndDownLoadCSV = false;
                }, err => {
                    this.generatingAndDownLoadCSV = false;
                    console.log(err)
                })
                break;
            case 1:
                this.apiService.getVisitsByStatus4Report("QUEUED", this.filter).subscribe(r => {
                    this.downloadFile(r, 'QUEUED');
                    this.generatingAndDownLoadCSV = false;
                }, err => {
                    this.generatingAndDownLoadCSV = false;
                    console.log(err)
                })
                break;
            case 2:
                this.apiService.getVisitsByStatus4Report("DONE", this.filter).subscribe(r => {
                    this.downloadFile(r, 'Done');
                    this.generatingAndDownLoadCSV = false;
                }, err => {
                    this.generatingAndDownLoadCSV = false;
                    console.log(err)
                })
                break;
        }


    }

    downloadFile(data, fileName) {
        //Download type xls
        //const contentType = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
        //Download type: CSV
        const contentType2 = 'text/csv';
        const blob = new Blob([data], { type: contentType2 });
        const url = window.URL.createObjectURL(blob);
        //Open a new window to download
        // window.open(url); 

        //Download by dynamically creating a tag
        const a = document.createElement('a');
        a.href = url;
        // a.download = fileName;
        a.download = fileName + '.csv';
        a.click();
        window.URL.revokeObjectURL(url);
    }

    getSelected() {
        return _.filter(this.rowsQueueOrDone, { checked: true })
    }

    flagItem(visitId) {
        this.apiService.flagVisits([visitId]).subscribe(r => {
    
            this.snotifyService.success('flagged this visit');
             _.remove(this.importErrors, a => {return a.visitId == visitId });
        })
    }

    flagRetryItem(err){
        err.importRetryFlagged = true
        this.visitIds.push(err.visitId)
        this.visitIds = _.uniq(this.visitIds)
        this.openLoading()
        this.apiService.flagRetryVisit(err.visitId).subscribe(r => {
            this.snotifyService.success('flagged retry this visit')
            this.closeLoading()
        })
    }

    openResetVisit(visit) {
        const dialogRef = this.matDialog.open(ResetVisitModalComponent, {
            minWidth: '1000px',
            data: {
                currentVisit: visit,
                assignedOfficeKeys: this.assignedOfficeKeys
            },
        });

        dialogRef.afterClosed().subscribe(result => {
            //this.getFlaggedRetry();
            if (result) {
                this.ngOnInit();
            }
        });
    }

    unqueue() {
        var ids = _.map(this.getSelected(), r => {
            return r.VisitId
        })
        this.unqueuing = true;
        this.apiService.unqueue(ids).subscribe(r => {
            this.unqueuing = false;
            this.getVisitsByStatus(1, "QUEUED");
        })

    }

    rerunImport() {
        var ids = _.map(this.getSelected(), r => {
            return r.VisitId
        });
        this.reruning = true;
        this.apiService.rerunImport(ids).subscribe(r => {
            this.reruning = false;
            this.getVisitsByStatus(1, "QUEUED");
        })
    }

    viewLogs(logs) {
        this.matDialog.open(ImportLogsComponent, {
            data: {
                logs: logs
            }
        })
    }


    fixMissingCharge(visit) {
        this.apiService.fixMissingCharge([visit.visitId]).subscribe(r => {
            //alert('fixed charge for visitId: ' + visitId )
            //this.getVisitsByStatus(this.page.CurrentPage, "DONE")
            // this.getPaymentImports(this.page.CurrentPage+2)
            visit.isSuccess = true
        }, err => {
            //this.getPaymentImports(this.page.CurrentPage+2)
            visit.isSuccess = true
        }
        )
    }

    viewError(item) {
        var responseRaw = item.responseRaw != null ? JSON.parse(item.responseRaw) : null
        this.matDialog.open(DialogImportLogComponent, {
            data: {
                responseRaw: responseRaw,
                responseRaw_XML: item.responseRaw_XML
            }
        });
    }

    onSortPaymentImport(event) {
        this.payloadPaymentImport.isAsc = event.newValue == "asc" ? true : false
        this.payloadPaymentImport.sortValue = event.sorts.length > 0 ? event.sorts[0]?.prop : ""
        this.getPaymentImports(0, this.payloadPaymentImport)
    }

    jsUcfirst(string) {
        return string.charAt(0).toUpperCase() + string.slice(1);
    }

    refetchPaymentImport() {
        this.payloadPaymentImport = {
            isAsc: false,
            sortValue: "",
            filterStartDate: moment().subtract(1, 'months').toDate(),
            filterEndDate: new Date()
        };
        this.getPaymentImports(0, this.payloadPaymentImport)
    }

    filterPaymentImport() {
        this.getPaymentImports(0, this.payloadPaymentImport)
    }

    showBoxMissing(type, visit) {
        this.visitIds.push(visit.visitId)
        this.visitIds = _.uniq(this.visitIds)
        console.log('visitIds', this.visitIds)
        // _.each(this.cardInfos, (item) => {
        //     visitIds = visitIds.concat(item.visitIds)
        // })
        return
        let cardInfo = {
            type: type,
            title: "",
            content: "",
            isRunning: false,
            visits: [],
            visitIds: [],
        }
        let indexExist = _.findIndex(this.cardInfos, (e) => { return e.type == type })
        if (indexExist >= 0) {
            let visitExist = _.find(this.cardInfos, (e) => { return e.type == type && _.includes(e.visitIds, visit.visitId) })
            if (!visitExist) {
                this.cardInfos[indexExist].visits.push(visit)
                this.cardInfos[indexExist].visitIds.push(visit.visitId)
            }
        } else {
            cardInfo.visits.push(visit)
            cardInfo.visitIds.push(visit.visitId)
        }
        switch (type) {
            case "pat":
                if (indexExist < 0) {
                    cardInfo.title = "Pats Retry"
                    this.cardInfos.push(cardInfo)
                }
                break;
            case "visit":
                if (indexExist < 0) {
                    cardInfo.title = "Visit Retry"
                    this.cardInfos.push(cardInfo)
                }
                break;
            case "chartdoc":
                if (indexExist < 0) {
                    cardInfo.title = "Chart Doc Retry"
                    this.cardInfos.push(cardInfo)
                }
                break;
            case "payer":
                if (indexExist < 0) {
                    cardInfo.title = "Payer Retry"
                    this.cardInfos.push(cardInfo)
                }
                break;
            case "patientdoc":
                if (indexExist < 0) {
                    cardInfo.title = "Patient Doc Retry"
                    this.cardInfos.push(cardInfo)
                }
                break;
            case "payment":
                if (indexExist < 0) {
                    cardInfo.title = "Payment Retry"
                    this.cardInfos.push(cardInfo)
                }
                break;
            case "charges":
                if (indexExist < 0) {
                    cardInfo.title = "Charges Retry"
                    this.cardInfos.push(cardInfo)
                }
                break;
        }
    }

    hiddenCard(index) {
        this.cardInfos = _.filter(this.cardInfos, (e, ind) => { return ind != index })
    }

    runMissingVisit(item, index) {
        if(item.type == "visit"){
            this.openSnackBar("Not implemented yet", "Close")
            return
        }
        let payload = {
            type: item.type,
            visitIds: item.visitIds
        }
        item.isRunning = true
        this.apiService.runMissingVisits(payload).subscribe((r) => {
            item.isRunning = false
            this.cardInfos = _.filter(this.cardInfos, (e, ind) => { return ind != index })
            this.getErrorImports()
            this.snotifyService.success("Run Missing Successfully!")
        }, err => {
            item.isRunning = false
            this.getErrorImports()
        })
    }

    editQuickInsurance(type, err) {
        console.log(err)
        const payerErrorType = err.importStatus_Payer ? err.importStatus_Payer.payerErrorType : ''
        const dialogRef = this.matDialog.open(UpdateAmdCodeInsuranceModalComponent, {
            width: '60vw',
            data: {
                visit: err,
                payerErrorType: payerErrorType
            },
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                err.importRetryFlagged = true
                this.showBoxMissing(type, err)
            }
        });
    }


    editQuickDiagCode(type, err) {
        const dialogRef = this.matDialog.open(UpdateDiagCodeModalComponent, {
            minWidth: '1000px',
            data: {
                visitId: err.visitId,
                officeKey: err.officeKey
            },
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result == "saved") {
                //this.getErrorImports()
                err.importRetryFlagged = true
                this.showBoxMissing(type, err)
            }
            this.getFlaggedRetry();
        });
    }

    editQuickModifierCode(type, err) {
        const dialogRef = this.matDialog.open(UpdateModifierCodeModalComponent, {
            minWidth: '1000px',
            data: {
                visitId: err.visitId,
                officeKey: err.officeKey
            },
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result == "saved") {
                //this.getErrorImports()
                err.importRetryFlagged = true
                this.showBoxMissing(type, err)
            }
            this.getFlaggedRetry();
        });
    }

    editQuickProcCode(type, err){
        const dialogRef = this.matDialog.open(UpdateVisitProcCodeModalComponent, {
            minWidth: '1000px',
            data: {
             visitId: err.visitId,
             officeKey: err.officeKey
            },
          });
      
          dialogRef.afterClosed().subscribe(result => {
            if (result == "saved") {
             //this.getErrorImports()
             err.importRetryFlagged = true
             this.showBoxMissing(type, err)
              }
              this.getFlaggedRetry();
          });
    }

    editQuickVisitChartDoc(type, err){
        const dialogRef = this.matDialog.open(UpdateVisitChartDocModalComponent, {
            minWidth: '1000px',
            data: {
             visitId: err.visitId,
             chartId: err.chartId
            },
          });
      
          dialogRef.afterClosed().subscribe(result => {
            if (result == "saved") {
                err.importRetryFlagged = true
                this.showBoxMissing(type, err)
            }
          });
    }
	

    openLoading() {
        this.dialogLoading = this.matDialog.open(DialogLoadingComponent, {
             minWidth: '150px',
             disableClose: true
         });
     }
    
     closeLoading() {
         this.dialogLoading.close()
     }
    

    openSnackBar(message: string, action: string) {
        this._snackBar.open(message, action);
    }

    checkSelected(type, visit){
        let cartInfo = _.find(this.cardInfos, (e) => {return e.type == type})
        return cartInfo && cartInfo.visitIds.includes(visit.visitId) ? true : false
    }

    automateFixPhysician(err){
      this.apiService.automateFixPhysician(err.clinicId, err.pvPhysicianName, moment(err.serviceDate).format('YYYY-MM-DD'), err.visitId).subscribe((r) => {
         if(r){
            this.getErrorImports()
         }
      })
    }

    rerunFlaggedRetryImport() {
        this.openLoading()
        let payload = {
            type: "",
            visitIds: this.visitIds
        }
        this.apiService.runMissingVisits(payload).subscribe((r ) => {
            this.closeLoading()
            this.getErrorImports()
            this.visitIds = []
            this.snotifyService.success("Run Missing Successfully!")
        }, err => {
            this.closeLoading()
            this.snotifyService.success("Run Missing Unsuccessfully!")
        })
    }

   

    viewChartNewTab(visit) {
        this._snackBar.open("loading chart...")
        this.apiService.getChartFilesByChartId(visit.chartId).subscribe(r => {
            if (r) {
                var charts = r;
                _.each(charts, chart => {
                    if (chart.fileName) {
                        this.loadPatientChart(visit, chart.fileName);
                    }
                })
            }      
        })
    }

    loadPatientChart(visit, fileName) {
        this.apiService.getPatientChart_v2(fileName).subscribe((blob: Blob) => {
            if (blob) {
                this.openChartInNewTab(blob);
            }
        }, (error) => {
            console.error('Error fetching PDF:', error);
        });
    }

    openChartInNewTab(blob) {
        const blobUrl = URL.createObjectURL(blob);
        const newTab = window.open();
        newTab.document.write(`<iframe width='100%' height='100%' src='${blobUrl}'></iframe>`);
        this._snackBar.dismiss();
    }

    editVisit(visit) {
        const dialogRef = this.matDialog.open(EditVisitModalComponent, {
            minWidth: '800px',
            data: {
                visit: visit
            },
        });

        dialogRef.afterClosed().subscribe(result => {
           
        });
    }

    getPaginationImportChanges() {
        this.visitSourceImportChanges = [];
        this.isLoadingVisitImportHistory = true;
        var lastResults = [];
        this.apiService.getVisitSourceImportHistories(this.pageVisitChangeEvent).subscribe(r => {
            this.pageVisitChangeEvent.RowCount = r.total
            if (r.results && r.results.length > 0) {           
                var groups = this.groupDataByLogNum(r.results);
                console.log(groups)
                if (groups && groups.length > 0) {
                    _.each(groups, gr => {
                        if (gr.importHistories && gr.importHistories.length > 1) {
                            var diffrent = this.getDiffrentOfFileChanges(gr.importHistories[0].recordXml, gr.importHistories[1].recordXml)
                            lastResults.push({ logNum: gr.logNum, changes: diffrent, patName: this.getPatName(gr) });

                        } else {
                            lastResults.push({ logNum: gr.logNum, changes: [{ path: '', oldValue: '', newValue: 'No data change', newValueIsObject: false }], patName: this.getPatName(gr) });
                        }
                    })
                    this.visitSourceImportChanges = lastResults;
                    this.isLoadingVisitImportHistory = false;
                }
            } else {
                this.isLoadingVisitImportHistory = false;
            }
        }, error => {
            this.isLoadingVisitImportHistory = false;
            console.log(error);
        })
    }

    getPatName(groupHistory) {
        var fullname = "";
        var recordXml = {} as any;

        if (Array.isArray(groupHistory.importHistories)) {
             recordXml = JSON.parse(groupHistory.importHistories[0]?.recordXml);
        } else  {
             recordXml = JSON.parse(groupHistory.importHistories.recordXml);
        }

        if (recordXml) {
            fullname = recordXml.Patient_Information.First_Name + " " + recordXml.Patient_Information.Last_Name;
        }
        if (!fullname || fullname == ' ') {
            recordXml = JSON.parse(groupHistory.importHistories[1]?.recordXml);
            if (recordXml) {
                fullname = recordXml.Patient_Information.First_Name + " " + recordXml.Patient_Information.Last_Name;
            }
        }
        return fullname;
    }


    updatePage(event: any) {
        this.pageVisitChangeEvent.PageNumber = event.offset;
        this.getPaginationImportChanges();
    }

    searchVisitSourceImportHistory() {
        this.pageVisitChangeEvent.PageNumber = 0;
        this.getPaginationImportChanges();
    }


    groupDataByLogNum(data: any[]): any[] {
        const groupedData = data.reduce((groups, item) => {
            const key = item.logNum;
            if (!groups[key]) {
                groups[key] = [];
            }
            groups[key].push(item);
            return groups;
        }, {});

        const results = Object.keys(groupedData).map(key => {
            const group = groupedData[key];

            if (group.length > 2 && group.some(item => item.recordType === 'changes')) {
                const latestChanges = group
                    .filter(item => item.recordType === 'changes')
                    .reduce((latest, current) =>
                        new Date(current.createdDate) > new Date(latest.createdDate) ? current : latest
                    );
                return { logNum: key, importHistories: latestChanges };
            } else {
                return { logNum: key, importHistories: group };
            }
        });

        return results;
    }


    getDiffrentOfFileChanges(obj1: any, obj2: any): any {
        if (typeof obj1 != 'object') {
            obj1 = JSON.parse(obj1);
        }
        if (typeof obj2 != 'object') {
            obj2 = JSON.parse(obj2);
        }
        const isEqual = deepEqual(obj1, obj2);

        if (!isEqual) {
            const editDifferences = deepDiff(obj1, obj2);
            const relevantEdits = editDifferences.map(edit => ({
                path: edit.path.join('.'),
                oldValue: edit.lhs,
                newValue: edit.rhs,
                newValueIsObject: (typeof edit.rhs === 'object' && edit.rhs != null && edit.rhs != undefined) ? true : false,
            }));
            return relevantEdits;
        }
        return null;
    }

    getObjectKeysAndValues(oldObject: any, newObject: any): { key: string, oldValue: any, newValue: any }[] {
        const result: { key: string, oldValue: any, newValue: any }[] = [];

        function processObject(obj1: any, obj2: any, prefix: string = '') {
            if (obj2 != null && obj2 != undefined) {
                if (Array.isArray(obj2)) {
                    for (let i = 0; i < obj2.length; i++) {
                        const oldO = obj1 && obj1[i] || {};
                        const newO = obj2[i] || {};
                        const arrayPath = prefix ? `${prefix}[${i}]` : `[${i}]`;

                        if (typeof newO === 'object') {
                            processObject(oldO, newO, arrayPath);
                        } else {
                            result.push({ key: arrayPath, oldValue: oldO, newValue: newO });
                        }
                    }
                } else if (typeof obj2 === 'object') {
                    const keys = Object.keys(obj2);

                    for (let i = 0; i < keys.length; i++) {
                        const key = keys[i];
                        const fullPath = prefix ? `${prefix}.${key}` : key;

                        var obj1Value = obj1 && typeof obj1 === 'object' && obj1[key] || null;
                        var obj2Value = obj2[key] || {};

                        if (typeof obj2Value === 'object') {
                            processObject(obj1Value, obj2Value, fullPath);
                        } else {
                            result.push({ key: fullPath, oldValue: obj1Value, newValue: obj2Value });
                        }
                    }
                }
            }
        }
        processObject(oldObject, newObject);
        return result;
    }

    isKeyDisplay(key) {
        if (_.includes(key.toLowerCase(), 'filename') || _.includes(key.toLowerCase(), 'short_name')) {
            return true;
        } else {
            return false;
        }
    }

    getPaginationChartChanges() {    
        this.visitImportChartChanges = [];
        this.isLoadingChartChange = true;
        this.apiService.getVisitSourceImportChartChanges(this.pageChartChangeEvent).subscribe(result => {
            this.pageChartChangeEvent.RowCount = result.total
            var temp = [];
            if (result.data) {
                const dataArray = _.map(result.data, (value, key) => ({
                    logNum: parseInt(key),
                    lastUpdatedChart: value.lastUpdatedChart,
                    visitSourceImportHistories: value.visitSourceImportHistories,
                    reUploadChart: value.reuploadChart
                }));
                const sortedArray = _.orderBy(dataArray, 'lastUpdatedChart', 'desc');
                _.each(sortedArray, item => {
                    const itemNew = _.find(item.visitSourceImportHistories, { recordType: 'new' });
                    const itemChange = _.find(item.visitSourceImportHistories, { recordType: 'changes' });
                    var getPatNamePayload = {
                        importHistories: itemNew
                    }
                    const patName = this.getPatName(getPatNamePayload);
                    const visitId = itemNew.visitId == itemChange.visitId ? itemNew.visitId : null;
                    temp.push(
                        {
                            logNum: item.logNum,
                            patName: patName,
                            visitId: visitId,
                            chartImportNew: this.getChartFromRecordXml(itemNew.recordXml),
                            chartImportChange: this.getChartFromRecordXml(itemChange.recordXml),
                            reUploadChart: item.reUploadChart
                        });
                })
                this.visitImportChartChanges = temp;   
                this.isLoadingChartChange = false;
            } else {
                this.isLoadingChartChange = false;
            }
        }, error => {
            this.isLoadingChartChange = false;
            console.log(error);
        })
    }

    isDirrentChart() {
        return false;
    }

    getChartFromRecordXml(recordXml) {
        var jsonRecord = JSON.parse(recordXml);
        return jsonRecord?.Patient_Information?.MedicalRecord?.Chart;
    }

    changePageChart(event) {
        this.pageChartChangeEvent.PageNumber = event.offset;
        this.getPaginationChartChanges();
    }


    reUploadChartDocument(row) {
        this.apiService.reUploadChartDocumentByFileName(row.chartImportChange.PvChartDocument, row.logNum, row.visitId).subscribe(r => {
            if (r && r.id) {
                row.reUploadChart = r;
            }
        })  
    }

    viewReuploadedChart(row) {
        var fileName = row.chartImportChange.PvChartDocument.FileName;
        this.apiService.getPatientChart_v2(fileName).subscribe((blob: Blob) => {
            if (blob) {
                this.openChartInNewTab(blob);
            }
        }, (error) => {
            console.error('Error fetching PDF:', error);
        });
    }

    dismissVisitReturn(visitReturn) {
        this.apiService.dismissVisitReturn(visitReturn.visitId).subscribe(r => {
            this.returnCount = 'loading..'
            this.getReturnedVisits()
        })
    }

    viewVisit_PvUpdate(err) {
        const errVisit = _.cloneDeep(err);
        const dialogRef = this.matDialog.open(ViewVisitPvUpdateModalComponent, {
            minWidth: '600px',
            data: {
                errVisit: errVisit              
            },
            disableClose: true
        })
    }

    openConfirmFlagRetryAllVisitsModal() {
        if (this.importErrorsCount == 0) {
            return;
        }
        var visitIds = this.importErrors.map(x => x.visitId);
        if (visitIds.length == 0) {
            return;
        }
        var mess = `Are you sure you want to flag retry for ${visitIds.length} visit(s)?`
        const dialogRef = this.matDialog.open(ConfirmModalComponent, {
            minWidth: '600px',
            data: {
                message: mess,
                title: "Confirm Flag Retry Visits"
            },
            disableClose: true
        })

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                this.apiService.flagRetryMultipleVisits(visitIds).subscribe(r => {
                    this.snotifyService.success("Successfully")
                    this.getErrorImports();
                }, error => {
                    this.snotifyService.error(error?.message)
                })
            }
        });
    }

    openConfirmResetEnqueueVisit(err) {
        if (err.amdVisitId) {
            return;
        }
        var mess = `Are you sure you want to reset enqueue visit: ${err.visitId} ?`
        const dialogRef = this.matDialog.open(ConfirmModalComponent, {
            minWidth: '600px',
            data: {
                message: mess,
                title: "Confirm Reset Enqueue Visit"
            },
            disableClose: true
        })

        dialogRef.afterClosed().subscribe(result => {
            if (result) {
                var payload = {
                    visitId: err.visitId,
                    officeKey: err.officeKey
                }
                this.apiService.resetEnqueueVisit(payload).subscribe(r => {
                    this.snotifyService.success("Reset Successfully")
                    this.getErrorImports();
                }, error => {
                    this.snotifyService.error(error?.message)
                })
            }
        });
    }


    downloadVisitReports() {
        this.downloadText = "Downloading ...";
        this.apiService.downloadVisitReports().subscribe(response => {

            const blob: Blob = response.body as Blob;
            const contentDisposition = response.headers.get('Content-Disposition');
            let fileName = 'downloaded-file.xlsx';

            if (contentDisposition) {
                const matches = /filename="([^"]+)"/.exec(contentDisposition);
                if (matches && matches[1]) {
                    fileName = matches[1];
                }
            }


            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = fileName;
            document.body.appendChild(a); 
            a.click();

            document.body.removeChild(a);
            window.URL.revokeObjectURL(url);
            this.downloadText = "Download Summary";
           
        }, err => {
            this.downloadText = "Download Error";
        });
    }

    openUpdateGuarantorInfo(err) {
        const dialogRef = this.matDialog.open(UpdateGuarantorInformationModalComponent, {
            minWidth: '1000px',
            data: {
                visit: err,
            },
        });

        dialogRef.afterClosed().subscribe(result => {
            if (result == "saved") {
                //this.getErrorImports()
                err.importRetryFlagged = true
                //this.showBoxMissing(type, err)
            }
            this.getFlaggedRetry();
        });
    }

    pullInsuranceAndResetAllErrs() {
        this.openLoading();
        var visitIds = this.importErrors.map(v => v.visitId);
        this.apiService.pullInsuranceAndResetUIQVisits(visitIds).subscribe(r => {
            console.log(r)
            this.closeLoading();
            this.getQueueImportLog();
            this.getErrorImports();
            this.getReturnedVisits();
            this.getVisitReturnDonePagination();
        }, err => {
            this.closeLoading();
        })
        //var mess = `Are you sure you want to pull insurances and reset the visits ?`
        //const dialogRef = this.matDialog.open(ConfirmModalComponent, {
        //    minWidth: '600px',
        //    data: {
        //        message: mess,
        //        title: "Confirm Pull And Reset Visits"
        //    },
        //    disableClose: true
        //})

        //dialogRef.afterClosed().subscribe(result => {
        //    if (result) {
        //        this.openLoading();
        //        this.apiService.pullInsuranceAndResetUIQVisits().subscribe(r => {
        //            console.log(r)
        //            this.closeLoading();
        //        }, err => {
        //            this.closeLoading();
        //        })
        //    }
        //});     
    }

    pullInsuranceAndReset(err) {
        this.openLoading();
        var visitIds = [err.visitId]
        this.apiService.pullInsuranceAndResetUIQVisits(visitIds).subscribe(r => {
            this.closeLoading();
            this.getQueueImportLog();
            this.getErrorImports();
            this.getReturnedVisits();
            this.getVisitReturnDonePagination();
        }, err => {
            this.closeLoading();
        })
    }

}
