<ng-container *ngIf="payerErrorType =='carrier'">
    <h1 mat-dialog-title>
        <ng-container *ngIf="isNewInsurance || isUpdateInsurance">
            {{isNewInsurance?'Add Insurance': 'Edit AmdCode Insurance'}}
        </ng-container>
    </h1>
</ng-container>


<ng-container *ngIf="payerErrorType =='resparty'">
    <h1 mat-dialog-title>Edit Ins Name</h1>

    <div class="patient-information" *ngIf="!isLoading && visit.pvPatient">
        <p>Patient Name: <strong> {{ visit.pvPatient.lastName }}, {{ visit.pvPatient.firstName}}</strong> </p>
        <p>Patient Dob: <strong> {{ visit.pvPatient.birthday | date : 'MM/dd/yyyy'}}</strong> </p>
        <p>Patient Phone: <strong>{{ visit.pvPatient.homePhone }}</strong> </p>
        <p>Patient Email: <strong>{{ visit.pvPatient.email }}</strong> </p>
        <p>Patient Address: <strong>{{visit.pvPatient.address1 }}, {{ visit.pvPatient.address2 }}, {{ visit.pvPatient.city }}, {{ visit.pvPatient.state }}, {{ visit.pvPatient.zip }} </strong> </p>
    </div>
</ng-container>

<div mat-dialog-content>
    <div *ngIf="isLoading">
        <mat-spinner></mat-spinner>
    </div>

    <div class="modal-body mx-3 row" *ngIf="!isLoading">
        <div class="col-lg-4" cdkDropListGroup>
            <ul class="list-group" cdkDropList (cdkDropListDropped)="dropToUpdatePayersPriority($event)">
                <li class="list-group-item" *ngFor="let item of insurances; let i = index"  cdkDrag [ngClass]="item.isActive? 'active' : '' ">
                    <div class="row-item">
                        <div>
                            <ng-container *ngIf="payerErrorType =='carrier'">
                                <div style="display: inline-flex">
                                    <h5>{{item.primaryName}} ({{item.type}})</h5>
                                    <span style=" margin-top: -5px;">
                                        <button mat-icon-button class="edit-insurance" aria-label="Edit Insurance"
                                                matTooltip="Edit Insurance" (click)="editInsurance(item)">
                                            <span class="glyphicon glyphicon-pencil" arial-hidden="true"></span>
                                        </button>
                                    </span>
                                </div>
                                <p>{{item.primaryPhone}}</p>
                                <p>{{item.primaryAddress}} - {{item.primaryCity}}</p>
                                <p>priority: {{item.priority}}</p>
                                <p class="warning text-warning error" *ngIf="!item.amdCode">AMDCODE missing</p>
                            </ng-container>

                            <ng-container *ngIf="payerErrorType =='resparty'">
                                <div style="display: inline-flex">
                                    <div>Ins Name: <span style="font-weight:bolder"> {{item.insName}}</span></div>
                                    <span style=" margin-top: -5px;">
                                        <button mat-icon-button class="edit-insurance" aria-label="Edit Ins Name"
                                                matTooltip="Edit InsName" (click)="editInsuranceName(item)">
                                            <span class="glyphicon glyphicon-pencil" arial-hidden="true"></span>
                                        </button>
                                    </span>
                                </div>
                                <p>Ins Address: {{item.insAddress1}}</p>
                                <p *ngIf="item.seekAMDInsName">SeekAMDInsName: {{item.seekAMDInsName}}</p>
                            </ng-container>
                        </div>
                        <div>
                            <p *ngIf="item.isExclude" style="color:red; opacity:0.62; margin-top:1em">Excluded</p>
                        </div>
                    </div>
                    <button mat-icon-button cdkDragHandle  title="Drag to update priority" ><span class="glyphicon glyphicon-move"></span> </button>
                </li>
            </ul>
            <div class="btn-add-new-box" *ngIf="payerErrorType =='carrier'">
                <button class="btn btn-secondary" (click)="changeViewAddInsurance()">Add Insurance</button>
            </div>
        </div>

        <div class="col-lg-8" style="border-left: 1px solid gray;">
            <ng-container *ngIf="payerErrorType =='carrier'">
                <div *ngIf="isNewInsurance">
                    <div>
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>MemberId: </mat-label>
                            <input matInput [(ngModel)]="newInsuranceObj.memberId">
                        </mat-form-field>
                    </div>

                    <div>
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>Group: </mat-label>
                            <input matInput [(ngModel)]="newInsuranceObj.groupId">
                        </mat-form-field>
                    </div>

                    <div>
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>Insurance: </mat-label>
                            <input matInput [formControl]="searchControl1" placeholder="Enter insurance name" [(ngModel)]="newInsuranceObj.insName" [matAutocomplete]="auto" />
                            <mat-autocomplete #auto="matAutocomplete">
                                <mat-option *ngFor="let item of searchInsurances | async; let i = index" [value]="item.primaryName" (click)="selectInsurance(item)">
                                    {{item.primaryName}}
                                </mat-option>
                            </mat-autocomplete>
                            <ng-container *ngIf="isSubmitted && !newInsuranceObj.insName">
                                <mat-error>Insurance is required</mat-error>
                            </ng-container>
                        </mat-form-field>
                    </div>

                    <div>
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>Type: </mat-label>
                            <mat-select [(ngModel)]="newInsuranceObj.type">
                                <mat-option *ngFor="let type of payerInformationTypes" [value]="type">{{type}} </mat-option>
                            </mat-select>
                            <ng-container *ngIf="isSubmitted && !newInsuranceObj.type">
                                <mat-error>Type is required</mat-error>
                            </ng-container>
                        </mat-form-field>
                    </div>

                    <div>
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>Class: </mat-label>
                            <input matInput type="number" [(ngModel)]="newInsuranceObj.class">
                            <ng-container *ngIf="isSubmitted && !newInsuranceObj.class">
                                <mat-error>Class is required</mat-error>
                            </ng-container>
                        </mat-form-field>

                    </div>

                    <div>
                        <mat-form-field appearance="outline" class="w-100">
                            <mat-label>Priority: </mat-label>
                            <input matInput type="number" [(ngModel)]="newInsuranceObj.priority">
                            <ng-container *ngIf="isSubmitted && !newInsuranceObj.priority">
                                <mat-error>Priority is required</mat-error>
                            </ng-container>
                        </mat-form-field>
                    </div>

                </div>

                <div *ngIf="isUpdateInsurance" class="row">
                    <form [formGroup]="insuranceForm">
                        <div class="form-group">
                            <mat-checkbox class="example-margin" (change)="updateInsuranceExclude()" formControlName="isExclude">Exclude</mat-checkbox>
                        </div>
                        <div class="form-group">
                            <mat-form-field appearance="outline" class="w-100" class="input-search">
                                <mat-label>Name: </mat-label>
                                <input matInput formControlName="primaryName" (mousedown)="onCarrierNameSelected($event)" (mouseup)="onCarrierNameSelected($event)">
                                <mat-icon matSuffix matTooltip="Search by name" (click)="onSearchCarrierName()">search</mat-icon>
                                <ng-container *ngIf="isSubmitted && insuranceF.primaryName.errors">
                                    <mat-error *ngIf="insuranceF.primaryName.errors.required">Name is required</mat-error>
                                </ng-container>
                            </mat-form-field>
                        </div>
                        <div class="form-group">
                            <mat-form-field appearance="outline" class="w-100">
                                <mat-label>Phone: </mat-label>
                                <input matInput formControlName="primaryPhone">
                            </mat-form-field>
                        </div>
                        <div class="form-group">
                            <mat-form-field appearance="outline" class="w-100" class="input-search">
                                <mat-label>Address 1: </mat-label>
                                <input matInput formControlName="primaryAddress1">
                                <mat-icon matTooltip="Search by address" matSuffix (click)="onSearchAmdCode($event)">search</mat-icon>
                                <ng-container *ngIf="isSubmitted && insuranceF.primaryAddress1.errors">
                                    <mat-error *ngIf="insuranceF.primaryAddress1.errors.required">
                                        Address1 is required
                                    </mat-error>
                                </ng-container>
                                <mat-hint *ngIf="selectedInsurance.isFinding">Searching...</mat-hint>
                            </mat-form-field>
                            <div *ngIf="carriers">
                                Found in AMD:
                                <a *ngFor="let c of carriers">
                                    <button (click)="fillAmdCode(c.carrierCode)">
                                        Code: {{c.carrierCode}}
                                        <br />
                                        Key: {{c.licenseKey}}
                                        <br />
                                        Name: {{c.carrierName}}
                                        <br />
                                        Address: {{ c.address }}{{ c.city ? ', ' + c.city : '' }}{{ c.state ? ', ' + c.state : '' }}
                                    </button>
                                </a>
                            </div>
                            <div *ngIf="!carriers">
                                Not found carrier that match this address
                            </div>
                        </div>

                        <div class="form-group">
                            <mat-form-field appearance="outline" class="w-100">
                                <mat-label>AmdCode: </mat-label>
                                <input matInput formControlName="amdCode">

                                <ng-container *ngIf="isSubmitted && insuranceF.amdCode.errors">
                                    <mat-error *ngIf="insuranceF.amdCode.errors.required">AmdCode is required</mat-error>
                                </ng-container>
                            </mat-form-field>
                        </div>
                        <div class="form-group">
                            <mat-form-field appearance="outline" class="w-100">
                                <mat-label>Address 2: </mat-label>
                                <input matInput formControlName="primaryAddress2">
                            </mat-form-field>
                        </div>
                        <div class="form-group">
                            <mat-form-field appearance="outline" class="w-100">
                                <mat-label>City: </mat-label>
                                <input matInput formControlName="primaryCity">
                                <ng-container *ngIf="isSubmitted && insuranceF.primaryCity.errors">
                                    <mat-error *ngIf="insuranceF.primaryCity.errors.required">City is required</mat-error>
                                </ng-container>
                            </mat-form-field>
                        </div>
                        <div class="form-group">
                            <mat-form-field appearance="outline" class="w-100">
                                <mat-label>State: </mat-label>
                                <input matInput formControlName="primaryState">
                                <ng-container *ngIf="isSubmitted && insuranceF.primaryState.errors">
                                    <mat-error *ngIf="insuranceF.primaryState.errors.required">State is required</mat-error>
                                </ng-container>
                            </mat-form-field>
                        </div>
                        <div class="form-group">
                            <mat-form-field appearance="outline" class="w-100">
                                <mat-label>Zip: </mat-label>
                                <input matInput formControlName="primaryZip">
                                <ng-container *ngIf="isSubmitted && insuranceF.primaryZip.errors">
                                    <mat-error *ngIf="insuranceF.primaryZip.errors.required">Zip is required</mat-error>
                                </ng-container>
                            </mat-form-field>

                        </div>
                    </form>
                </div>
            </ng-container>

            <ng-container *ngIf="payerErrorType =='resparty'">
                <div class="col-lg-8" style="border-left: 1px solid gray;">


                    <div style="min-height:500px;" *ngIf="isEditInsName">
                        <div class="row">
                            <div class="col-md-10 col-sm-10 col-lg-10">
                                <mat-form-field appearance="outline" class="w-100">
                                    <mat-label>Ins Name: </mat-label>
                                    <input matInput [(ngModel)]="lookUpInsName" name="lookUpInsName">
                                </mat-form-field>
                            </div>
                            <div class="col-md-2 col-sm-2 col-lg-2" style="margin-top:10px">
                                <button class="btn btn-secondary" (click)="lookUpResPartyByInsName()">Lookup</button>
                            </div>
                        </div>

                        <div class="row" *ngIf="islookUpResParty" style="display: flex; height: 350px;">
                            <mat-spinner style="margin:auto"></mat-spinner>
                        </div>

                        <div class="row" *ngIf="!islookUpResParty && lookupResponse?.Resppartylist?.Respparty?.length > 0">
                            <div class="respparty-container">
                                <div class="col-12" *ngFor="let respparty of lookupResponse.Resppartylist.Respparty">
                                    <div class="respparty-box row">
                                        <div class="col-md-8 col-sm-12 col-lg-8" style=" margin: 0;">
                                            <h3>{{ respparty.Name }}</h3>
                                            <p><strong>Address:</strong> {{ respparty.Address.Address1 }}, {{ respparty.Address.Address2 }}, {{ respparty.Address.City }}, {{ respparty.Address.State }}, {{ respparty.Address.Zip }} {{ respparty.Address.Countrycode }}</p>
                                            <p><strong>Phone:</strong> {{ respparty.Contactinfo.Homephone }}</p>
                                            <p><strong>Account Number:</strong> {{ respparty.Acctnum }}</p>
                                            <p><strong>Date of Birth:</strong> {{ respparty.Dob }}</p>
                                            <p><strong>Email:</strong> {{ respparty.Email }}</p>
                                        </div>
                                        <div class="col-md-3 col-sm-12 col-lg-3 box-btn-choose">
                                            <button class="btn btn-secondary" (click)="saveUpdateGuarantorImportLog(respparty)"> {{respartyIdSaved == respparty.Id ? 'Saved' : 'Choose and Save'}}</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </ng-container>
        </div>
    </div>

    <div class="results-search-carrierName" *ngIf="!isLoading && searchCarrierName.isShowResults">
        <ng-container *ngIf="searchCarrierName.isLoading">
            <mat-spinner></mat-spinner>
        </ng-container>

        <ng-container *ngIf="!searchCarrierName.isLoading && searchCarrierName.results.length > 0">
            <div *ngFor="let carrier of searchCarrierName.results" class="carrier-item">
                <h5>{{ carrier.carrierName }}</h5>
                <p><strong>Carrier Code:</strong> {{ carrier.carrierCode }}</p>
                <p><strong>Address:</strong> {{ carrier.address }},  {{ carrier.city }}, {{ carrier.state }}, {{ carrier.zipCode }}</p>
                <button (click)="copySelectedToFrom(carrier)" class="copy-button">Copy</button>
            </div>
        </ng-container>
        <ng-container *ngIf="!searchCarrierName.isLoading && searchCarrierName.results.length == 0">
            Not found carrier that match keywoed: '{{searchCarrierName.keyword}}'
        </ng-container>
    </div>


</div>

<div mat-dialog-actions style="justify-content: end;">
    <button class="btn btn-secondary" style="margin-right: 5px" (click)="closeDialog()">Close</button>
    <ng-container *ngIf="payerErrorType =='carrier'">
        <button *ngIf="isUpdateInsurance" class="btn btn-primary" [disabled]="insuranceForm.invalid" (click)="saveUpdate()">
            <ng-container *ngIf="!isSaving">Update & Re-queue</ng-container>
            <ng-container *ngIf="isSaving">Updating...</ng-container>
        </button>

        <button *ngIf="isNewInsurance" class="btn btn-primary" (click)="saveAddInsurance()">
            <ng-container *ngIf="!isSaving">Add New</ng-container>
            <ng-container *ngIf="isSaving">Saving...</ng-container>
        </button>
    </ng-container>
</div>

