<mat-tab-group animationDuration="0ms" (selectedTabChange)="tabChanged($event)" [(selectedIndex)]="currentTabIndex">

    <mat-tab [label]="queueCount? 'Queued (' + queueCount + ')' : 'Queued'">
        <div style="margin-top: 10px">
            <table class="table table-bordered table-responsive">
                <thead>
                    <tr>
                        <th>
                            VisitId/Service Date
                        </th>
                        <th>FinClass/OfficeKey</th>
                        <th>ClinicId/PhysicianId</th>
                        <th>Patient</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let err of importQueued">
                        <td>
                            {{err.visitId}}<br />
                            {{err.serviceDate | date :'medium'}}
                        </td>
                        <td>
                            {{err.finClass}}<br />
                            {{err.officeKey}}
                        </td>
                        <td>
                            {{err.clinicId}}<br />
                            {{err.physicianId}}
                        </td>
                        <td>
                            {{err.pvPatient?.firstName}} {{err.pvPatient?.lastName}}<br />

                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </mat-tab>


    <mat-tab label="Error {{errorCount}} ({{importErrorsCount}})">
        <div style="margin-top: 10px">
            <div style=" background: white;  margin: 10px;">
                <mat-form-field>
                    <mat-label>Visit Start Date</mat-label>
                    <input matInput [matDatepicker]="picker" [(ngModel)]="filter.visitStartDate"
                           (dateChange)="setPage({offset: 0})">
                    <mat-datepicker-toggle matSuffix [for]="picker"></mat-datepicker-toggle>
                    <mat-datepicker #picker></mat-datepicker>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Visit End Date</mat-label>
                    <input matInput [matDatepicker]="pickerEnd" [(ngModel)]="filter.visitEndDate"
                           (dateChange)="setPage({offset: 0})">
                    <mat-datepicker-toggle matSuffix [for]="pickerEnd"></mat-datepicker-toggle>
                    <mat-datepicker #pickerEnd></mat-datepicker>
                </mat-form-field>

                <button mat-raised-button (click)="getErrorImports()">Search</button>

            </div>

            <div style="display:flex">
                <div class="retry-legend-container">
                    <div style="display: inline-flex; margin-left: 20px;">
                        <div class="visit-updated-legend"></div> <span>Pending Retry</span>
                    </div>
                    <div style="display: inline-flex; margin-left: 20px;">
                        <div class="visit-failed-legend"></div>
                        <span>Failed Retry</span>
                    </div>

                </div>
                <div class="colors-legend-container" style="margin-left: 300px;">
                    <div style="display: inline-flex; margin-left: 20px;">
                        <div class="item-legend New"></div> <span>Pending</span>
                    </div>
                    <div style="display: inline-flex; margin-left: 20px;">
                        <div class="item-legend Done"></div> <span>Done</span>
                    </div>
                    <div style="display: inline-flex; margin-left: 20px;">
                        <div class="item-legend Fail"></div> <span>Error</span>
                    </div>
                </div>


                <div class="colors-legend-container" style="margin-left: 300px; display: flex;">
                    <div>
                        <button mat-raised-button (click)="downloadVisitReports()">{{downloadText}}</button>
                    </div>
                    <div style="margin-left: 20px" *ngIf="companyEMR != null && companyEMR.emR_Id == 2">
                        <button mat-raised-button (click)="pullInsuranceAndResetAllErrs()">Pull Insurance and Reset</button>
                    </div>
                </div>

            </div>



            <div class="list-scroll">
                <span *ngIf="isLoadingError" style="color: gray;margin-bottom: 10px;">Loading...</span>
                <ul *ngIf="!isLoadingError">
                    <li *ngFor="let err of importErrors">
                        <!--  class="hl-{{highlightVisitId == err.visitId}}" -->
                        <div style="padding: 8px 2px;" [ngClass]="err.importRetryFlagged ? 'visit-updated' :''" class="{{err.retryCount>=5 ?'visit-failed':''}}">
                            <div class="err-row">
                                <div style="display:inline-flex" class="info-err">
                                    <span>
                                        {{err.pvPatient?.firstName}} {{err.pvPatient?.lastName}}<br />
                                        DOB: {{err.pvPatient.birthday | date : 'MM/dd/yyyy'}}
                                    </span>
                                    <span>
                                        MRM: {{err.visitId}}<br />

                                        DOS: {{err.serviceDate | date : 'MM/dd/yyyy' }}
                                    </span>
                                    <span>
                                        AMD: {{err.amdVisitId}}<br />
                                        {{err.officeKey}} ({{err.finClass}})
                                    </span>
                                    <span class="physician-info">
                                        EMR: {{err.visitEMRId}}<br />
                                        {{err.physician?.displayName}} <span style="font-size: 10px;">({{err.clinicId}})</span>
                                    </span>

                                    <span>
                                        <!--<button (click)="clearErrorAndEnqueue(err.visitId)">Clear Error And Enqueue Again </button>-->
                                        <button mat-icon-button [matMenuTriggerFor]="menu"
                                                aria-label="Example icon-button with a menu">
                                            <mat-icon>more_vert</mat-icon>
                                        </button>
                                        <mat-menu #menu="matMenu">
                                            <button (click)="flagItem(err.visitId)" mat-menu-item>Flag</button>
                                            <button (click)="flagRetryItem(err)" mat-menu-item>Flag Retry</button>
                                            <button [disabled]="err.importStatus_Pat && !err?.importStatus_Pat?.hasError" (click)="clearErrorAndEnqueue(err.visitId)" mat-menu-item>Enqueue</button>
                                            <button *ngIf="!err.amdVisitId" (click)="openConfirmResetEnqueueVisit(err)" mat-menu-item> Reset-Enqueue</button>
                                            <button (click)="openResetVisit(err)" mat-menu-item>Open Reset-Visit</button>
                                            <button (click)="viewChartNewTab(err)" mat-menu-item>View Chart</button>
                                            <button (click)="editVisit(err)" mat-menu-item>Edit Visit</button>
                                            <button *ngIf="companyEMR != null && companyEMR.emR_Id == 2 && err.emR_ID ==2" (click)="pullInsuranceAndReset(err)" mat-menu-item>Pull Insurance and Reset</button>
                                        </mat-menu>
                                    </span>
                                </div>
                                <div style="display:flex" class="status-container" *ngIf="err.isShowRound">
                                    <span>
                                        <div matTooltip="{{err?.importStatus_Pat?.message}}">
                                            <div class="import-row">
                                                <div class="{{err.mainStatus}} importStatus-indicator {{err.importStatus_Pat ? 'logged':'not-run'}} {{err?.importStatus_Pat?.hasError ? 'has-error':''}}">
                                                </div>
                                                <!-- <button [ngClass]="checkSelected('pat', err) ? 'selected' : ''" class="runMissing" matTooltip="Run Missing"  (click)="showBoxMissing('pat', err)"
                        *ngIf="!err.importStatus_Pat || err?.importStatus_Pat?.hasError">
                        <span class="glyphicon glyphicon-plus-sign" arial-hidden="true"></span>
                    </button> -->


                                            </div>
                                            <label>
                                                Pat
                                            </label>
                                        </div>
                                    </span>
                                    <span>
                                        <div matTooltip="{{err?.importStatus_Visit?.message}}">
                                            <div class="import-row">
                                                <div class="importStatus-indicator {{err.importStatus_Visit ? 'logged':'not-run'}} {{err?.importStatus_Visit?.hasError ? 'has-error':''}}">
                                                </div>
                                                <!-- <button [ngClass]="checkSelected('visit', err) ? 'selected' : ''" class="runMissing" matTooltip="Run Missing"  (click)="showBoxMissing('visit', err)"
                        *ngIf="!err.importStatus_Visit || err?.importStatus_Visit?.hasError">
                        <span class="glyphicon glyphicon-plus-sign" arial-hidden="true"></span>
                    </button> -->
                                            </div>
                                            <label>
                                                Visit
                                            </label>
                                        </div>
                                    </span>
                                    <span>
                                        <div matTooltip="{{err?.importStatus_ChartDoc?.message}}">
                                            <div class="import-row">
                                                <div (click)="viewDoc(err)" class="importStatus-indicator {{err.importStatus_ChartDoc ? 'logged':'not-run'}} {{err?.importStatus_ChartDoc?.hasError ? 'has-error':''}}">
                                                </div>
                                                <!-- <button [ngClass]="checkSelected('chartdoc', err) ? 'selected' : ''" class="runMissing" matTooltip="Run Missing"  (click)="showBoxMissing('chartdoc', err)"
                        *ngIf="!err.importStatus_ChartDoc || err?.importStatus_ChartDoc?.hasError">
                        <span class="glyphicon glyphicon-plus-sign" arial-hidden="true"></span>
                    </button> -->
                                                <button class="editInsurance" (click)="editQuickVisitChartDoc('chartdoc', err)" matTooltip="Edit Chart Doc Quick"
                                                        *ngIf="err.isShowUpdateQuickChart">
                                                    <span class="glyphicon glyphicon-pencil" arial-hidden="true"></span>
                                                </button>
                                            </div>
                                            <label>
                                                ChartDoc
                                            </label>
                                        </div>
                                    </span>
                                    <span>
                                        <div matTooltip="{{err?.importStatus_Payer?.message}}">
                                            <div class="import-row">
                                                <div class="importStatus-indicator {{err.importStatus_Payer ? 'logged':'not-run'}} {{err?.importStatus_Payer?.hasError ? 'has-error':''}}">
                                                </div>
                                                <!-- <button [ngClass]="checkSelected('payer', err) ? 'selected' : ''" class="runMissing" matTooltip="Run Missing" (click)="showBoxMissing('payer', err);"
                        *ngIf="!err.importStatus_Payer || err?.importStatus_Payer?.hasError">
                        <span class="glyphicon glyphicon-plus-sign" arial-hidden="true"></span>
                    </button> -->
                                                <button class="editInsurance" (click)="editQuickInsurance('payer', err)" [matTooltip]="(err.importStatus_Payer && err.importStatus_Payer.payerErrorType =='carrier')?'Edit AmdCode Insurance Quick':'Edit InsName'"
                                                        *ngIf="err.isShowUpdateQuick && err.importStatus_Payer.payerErrorType != ''">
                                                    <span class="glyphicon glyphicon-pencil" arial-hidden="true"></span>
                                                </button>
                                            </div>
                                            <label>
                                                Payer
                                            </label>
                                        </div>
                                    </span>
                                    <span>
                                        <div matTooltip="{{err?.importStatus_PatientDoc?.message}}">
                                            <div class="import-row">
                                                <div class="importStatus-indicator {{err.importStatus_PatientDoc ? 'logged':'not-run'}} {{err?.importStatus_PatientDoc?.hasError ? 'has-error':''}}">
                                                </div>
                                                <!-- <button [ngClass]="checkSelected('patientdoc', err) ? 'selected' : ''" class="runMissing" matTooltip="Run Missing"  (click)="showBoxMissing('patientdoc', err)"
                        *ngIf="!err.importStatus_PatientDoc || err?.importStatus_PatientDoc?.hasError">
                        <span class="glyphicon glyphicon-plus-sign" arial-hidden="true"></span>
                    </button> -->
                                            </div>
                                            <label>
                                                PatientDoc
                                            </label>
                                        </div>
                                    </span>
                                    <span>
                                        <div matTooltip="{{err?.importStatus_Payment?.message}}">
                                            <div class="import-row">
                                                <div class="importStatus-indicator {{err.importStatus_Payment ? 'logged':'not-run'}} {{err?.importStatus_Payment?.hasError ? 'has-error':''}}">
                                                </div>
                                                <!-- <button [ngClass]="checkSelected('payment', err) ? 'selected' : ''" class="runMissing" matTooltip="Run Missing"  (click)="showBoxMissing('payment', err)"
                        *ngIf="!err.importStatus_Payment || err?.importStatus_Payment?.hasError">
                        <span class="glyphicon glyphicon-plus-sign" arial-hidden="true"></span>
                    </button> -->
                                            </div>
                                            <label>
                                                Payment
                                            </label>
                                        </div>
                                    </span>
                                    <span>
                                        <div matTooltip="{{err?.importStatus_Charges?.message}}">
                                            <div class="import-row">
                                                <div class="importStatus-indicator {{err.importStatus_Charges ? 'logged':'not-run'}} {{err?.importStatus_Charges?.hasError ? 'has-error':''}}">
                                                </div>
                                                <button class="editProcCode" (click)="editQuickProcCode('charges', err)" *ngIf="err.isShowUpdateProcCode">
                                                    <span class="glyphicon glyphicon-pencil" arial-hidden="true"></span>
                                                    Edit ProcCode
                                                </button>

                                                <button style="background-color:coral" class="editProcCode" (click)="editQuickDiagCode('charges', err)" *ngIf="err.isShowUpdateDiagCode">
                                                    <span class="glyphicon glyphicon-pencil" arial-hidden="true"></span>
                                                    Edit DiagCode
                                                </button>
                                                <button style="background-color:mediumslateblue" class="editProcCode" (click)="editQuickModifierCode('charges', err)" *ngIf="err.isShowUpdateModifierCode">
                                                    <span class="glyphicon glyphicon-pencil" arial-hidden="true"></span>
                                                    Edit Modifier Code
                                                </button>

                                            </div>
                                            <label>
                                                Charges
                                            </label>
                                        </div>
                                    </span>
                                </div>
                                <div class="import-row">
                                    <button class="editInsurance" (click)="openUpdateGuarantorInfo(err)" matTooltip="Update Guarantor Information"
                                            *ngIf="err.isShowUpdateGuarantor">
                                        <span class="glyphicon glyphicon-pencil" arial-hidden="true"></span>
                                    </button>
                                </div>
                            </div>
                            <div class="text-erorr" *ngIf="!err.isShowRound" style="display: grid;">    
                                <span><strong>Error:</strong> {{err?.importStatus_Pat?.message}}</span>
                            </div>
                            <div class="text-erorr" *ngIf="err.isShowUpdateOfficeKey">
                                <span><strong>Error: </strong>Please run Open Reset-Visit with the correct office key. <a style="cursor:pointer" (click)="openResetVisit(err)">Open Reset-Visit</a> </span>
                            </div>
                        </div>

                        <!-- <hr /> -->
                    </li>
                </ul>
            </div>

            <div style="margin-top: 20px;">
                <small>
                    There are <strong>{{flaggedRetryCount}}</strong> flagged-for-retry  visits. These will be processed when queue is empty.
                </small>
                <a style="cursor: pointer; margin-left: 10px;"  (click)="openConfirmFlagRetryAllVisitsModal()">Flag Retry All</a>
            </div>


            <!-- <label *ngIf="cardInfos?.length > 0">
        Retry boxes
    </label>

    <div class="row col-md-12">
        <mat-card class="retry-card col-md-2" *ngFor="let item of cardInfos; let ind = index">
            <mat-card-header>
                <mat-card-title>{{item.title}}</mat-card-title>
                <button (click)="hiddenCard(ind)" class="btn-remove">
                    <span class="glyphicon glyphicon-remove" arial-hidden="true"></span>
                </button>
            </mat-card-header>
            <mat-card-content>
                <p>This card contains visitIds to retry import </p>
                <div style="margin-bottom: 8px;">
                    <span>VisitIds: </span>
                    <span class="badge-visit" *ngFor="let visitId of item.visitIds">
                        {{visitId}}
                    </span>
                </div>
                <mat-divider></mat-divider>
            </mat-card-content>
            <mat-card-actions align="end">
                <button mat-raise-button (click)="runMissingVisit(item, ind)">Run</button>
            </mat-card-actions>
            <mat-card-footer>
                <mat-progress-bar *ngIf="item.isRunning" mode="indeterminate"></mat-progress-bar>
            </mat-card-footer>
        </mat-card>
    </div> -->
        </div>
    </mat-tab>

    <mat-tab label="Return ({{returnCount}})">
        <div style="margin-top: 10px">
            <table class="table table-bordered table-responsive table-visitReturn" style="background:white;">
                <thead>
                    <tr>
                        <th>Visit Info</th>
                        <th>Sent To</th>
                        <th>Subject</th>
                        <!--<th>Message</th>-->
                        <th>Updated Date</th>
                        <th>Status</th>
                    </tr>
                </thead>
                <tbody>
                    <tr *ngFor="let err of returnedVisits">
                        <td style="width:30% !important;">
                            <div class="row">
                                <div class="col-md-4 col-sm-12">
                                    {{err.patientInformation.name}}<br />
                                    Dob : {{err.patientInformation.dob | date :'MM/dd/yyyy'}}
                                </div>
                                <div class="col-md-4 col-sm-12">
                                    MRM: {{err.visit.visitId}}<br />
                                    DOS: {{err.visit.serviceDate | date :'MM/dd/yyyy'}}
                                </div>
                                <div class="col-md-4 col-sm-12">
                                    PVM: {{err.visit.pvlogNum}}<br />
                                    {{err.visit.pvPhysicianName}}({{err.visit.clinicId}})
                                </div>
                            </div>
                        </td>
                        <td>
                            <p *ngIf="err.physician"> {{err.physician.lastName}}, {{err.physician.firstName}}</p>
                        </td>
                        <td>
                            {{err.visitReturn.subject}}
                        </td>
                        <!--<td>
                            {{err.visitReturn.message}}
                        </td>-->
                        <td>
                            <span *ngIf="err.visitReturn.updatedRecievedOn">{{err.visitReturn.updatedRecievedOn | date :'MM/dd/yyyy'}}</span>
                        </td>
                        <td>
                            <span *ngIf="err.visitReturn.returnStatus==0" class="badge badge-sent-provider">Sent To Provider</span>
                            <span *ngIf="err.visitReturn.returnStatus==1" class="badge badge-update-received" (click)="viewVisit_PvUpdate(err)">Update Received</span>
                            <span *ngIf="err.visitReturn.returnStatus==2" class="badge badge-updated-merge">Update Merged</span>
                            <span class="badge badge-dismiss" (click)="dismissVisitReturn(err.visitReturn)">Dismiss</span>
                        </td>
                    </tr>
                </tbody>
            </table>
        </div>
    </mat-tab>

    <mat-tab [label]="isLoadingVisitImportHistory ? 'Changes' : 'Changes (' + pageVisitChangeEvent.RowCount + ')'">

        <div class="visit-import-history-change">


            <div class="row group-search">
                <div class="col-md-10 col-sm-12">
                    <mat-form-field appearance="fill" style="width:100%">
                        <mat-label>lognum, firstname lastname</mat-label>
                        <input matInput [(ngModel)]="pageVisitChangeEvent.SearchText">
                    </mat-form-field>
                </div>
                <div class="col-md-2 col-sm-12">
                    <button mat-raised-button (click)="searchVisitSourceImportHistory()">Search</button>
                </div>
            </div>

            <ngx-datatable class="material" [rows]="visitSourceImportChanges" [headerHeight]="50" [footerHeight]="50" rowHeight="auto"
                           [scrollbarH]="true" [loadingIndicator]="isLoadingVisitImportHistory" [count]="pageVisitChangeEvent.RowCount" [offset]="pageVisitChangeEvent.PageNumber"
                           [limit]="pageVisitChangeEvent.PageSize" (page)="updatePage($event)" [externalPaging]="true" [virtualization]="false">
                <ngx-datatable-column name="Log Num" [width]="150">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                        <div style="display:grid">
                            <span>{{row.logNum}}</span>
                            <span>{{row.patName}}</span>
                        </div>
                    </ng-template>
                </ngx-datatable-column>
                <ngx-datatable-column name="Changes" [width]="1000">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                        <table style="width:100%">
                            <ng-container *ngFor="let change of row.changes">
                                <tr *ngIf="change.newValue" style="height:30px; border-bottom:1px solid grey">
                                    <td style="width:100%">
                                        <div *ngIf="change.path">
                                            <span><b>Path: </b>{{ change.path }}</span>
                                        </div>
                                        <div style="display:grid">
                                            <span *ngIf="!change.newValueIsObject">
                                                <b>Value Change: </b>{{ change.oldValue ? change.oldValue + ' -> ' : "" }}{{ change.newValue }}
                                            </span>
                                            <div *ngIf="change.newValueIsObject">
                                                <span><b>Value Change: </b></span>
                                                <ul>
                                                    <ng-container *ngFor="let entry of getObjectKeysAndValues(change.oldValue, change.newValue)">
                                                        <li *ngIf="isKeyDisplay(entry.key)">
                                                            {{ entry.key }}: {{ entry.oldValue ? entry.oldValue + ' -> ' : "" }} {{ entry.newValue }}
                                                        </li>
                                                    </ng-container>
                                                </ul>
                                            </div>
                                        </div>
                                    </td>
                                </tr>
                            </ng-container>


                        </table>
                    </ng-template>
                </ngx-datatable-column>
            </ngx-datatable>

        </div>
    </mat-tab>


    <mat-tab [label]="isLoadingChartChange ? 'Chart Changes' : 'Chart Changes (' + pageChartChangeEvent.RowCount + ')'">

        <div class="visit-import-history-change">

            <div style=" background: white;  margin: 10px;">
                <mat-form-field>
                    <mat-label>Import Start Date</mat-label>
                    <input matInput [matDatepicker]="pickerChartStart" [(ngModel)]="pageChartChangeEvent.startDate">
                    <mat-datepicker-toggle matSuffix [for]="pickerChartStart"></mat-datepicker-toggle>
                    <mat-datepicker #pickerChartStart></mat-datepicker>
                </mat-form-field>
                <mat-form-field>
                    <mat-label>Import End Date</mat-label>
                    <input matInput [matDatepicker]="pickerChartEnd" [(ngModel)]="pageChartChangeEvent.endDate">
                    <mat-datepicker-toggle matSuffix [for]="pickerChartEnd"></mat-datepicker-toggle>
                    <mat-datepicker #pickerChartEnd></mat-datepicker>
                </mat-form-field>
                <button mat-raised-button (click)="changePageChart({offset:0})">Search</button>
            </div>

            <ngx-datatable class="material" [rows]="visitImportChartChanges" [headerHeight]="50" [footerHeight]="50" rowHeight="auto"
                           [scrollbarH]="true" [loadingIndicator]="isLoadingChartChange" [count]="pageChartChangeEvent.RowCount" [offset]="pageChartChangeEvent.PageNumber"
                           [limit]="pageChartChangeEvent.PageSize" (page)="changePageChart($event)" [externalPaging]="true" [virtualization]="false">
                <ngx-datatable-column name="Log Num" [width]="200">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                        <div style="display:grid">
                            <span>{{row.logNum}}</span>
                            <span>{{row.patName}}</span>
                        </div>
                    </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column name="Chart New" [width]="400">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                        <ng-container *ngIf="row.chartImportNew">
                            <div style="display:grid">
                                <span>File name: <b>{{row.chartImportNew.PvChartDocument.FileName}}</b></span>
                                <span>Number of page: <b>{{row.chartImportNew.PvChartDocument.NumberOfPages}}</b></span>
                                <span>Last updated by: <b>{{row.chartImportNew?.PvChartDocument?.LastUpdatedBy}}</b></span>
                                <span>Last updated on: <b>{{row.chartImportNew.PvChartDocument.LastUpdatedOn  | date :'MM/dd/yyyy'}}</b></span>
                            </div>
                        </ng-container>
                        <ng-container *ngIf="!row.chartImportNew">
                            <span>Chart not imported</span>
                        </ng-container>
                    </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column name="Chart Last Change" [width]="400">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                        <div style="display:grid">
                            <span>File name: <b>{{row.chartImportChange.PvChartDocument.FileName}}</b></span>
                            <span>Number of page: <b>{{row.chartImportChange.PvChartDocument.NumberOfPages}}</b></span>
                            <span>Last updated by: <b>{{row.chartImportChange?.PvChartDocument?.LastUpdatedBy}}</b></span>
                            <span>Last updated on: <b>{{row.chartImportChange.PvChartDocument.LastUpdatedOn  | date :'MM/dd/yyyy'}}</b></span>
                        </div>
                    </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column name="Action" [width]="200">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                        <button mat-raised-button *ngIf="!row.reUploadChart" (click)="reUploadChartDocument(row)">Re-upload chart</button>
                        <div *ngIf="row.reUploadChart && row.reUploadChart.id">
                            <p style="white-space: nowrap;">Re-uploaded on {{row.reUploadChart.reUploadedDate | date :'MM/dd/yyyy' }}</p>
                            <span style="color: #3c8dbc; cursor: pointer; white-space: nowrap;" (click)="viewReuploadedChart(row)">View chart on new tab</span>
                        </div>
                    </ng-template>
                </ngx-datatable-column>

            </ngx-datatable>
        </div>
    </mat-tab>

    <mat-tab [label]="pageVisitDoneEvent.rowCount ? 'Sent (' + pageVisitDoneEvent.rowCount + ')' : 'Sent'">
        <div style="margin-top: 10px">
            <ngx-datatable class="material" [rows]="visitImportStautsDones" [headerHeight]="50" [footerHeight]="40" rowHeight="auto"
                           [scrollbarH]="true" [loadingIndicator]="pageVisitDoneEvent.isLoading" [count]="pageVisitDoneEvent.rowCount" [offset]="pageVisitDoneEvent.pageNumber"
                           [limit]="pageVisitDoneEvent.pageSize" (page)="changePageVisitDoneEvent($event)" [externalPaging]="true" [virtualization]="false">
                <ngx-datatable-column name="VisitId/ VisitDate" [width]="400">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                        <div style="display:grid; align-items: center">
                            <p>{{row.visitId}}</p>
                            <p>{{row.serviceDate  | date :'medium'}}</p>
                        </div>
                    </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column name="ClinicId / PhysicianId" [width]="400">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                        <div style="display:grid; align-items: center">
                            <p>{{row.clinicId}}</p>
                            <p>{{row.physicianId}}</p>
                        </div>
                    </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column name="FinClass / OfficeKey" [width]="300">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                        <div style="display:grid; align-items: center">
                            <p>{{row.finClass}}</p>
                            <p>{{row.officeKey}}</p>
                        </div>
                    </ng-template>
                </ngx-datatable-column>

                <ngx-datatable-column name="Patient" [width]="400">
                    <ng-template let-row="row" ngx-datatable-cell-template>
                        <div style="display:grid; align-items: center">                        
                            <p>{{row.pvPatient?.firstName}} {{row.pvPatient?.lastName}}</p>
                        </div>
                    </ng-template>
                </ngx-datatable-column>


            </ngx-datatable>
        </div>
    </mat-tab>


</mat-tab-group>